@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700&display=swap");
:root {
  --color: rgb(78, 81, 81);
  --background-color: rgb(255, 255, 255);
  --font-family: 'Open Sans', sans-serif;
  --forn-size: 14px;
}
  
* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  color: var(--color);
  background-color: var(--background-color);
  font-family: var(--font-family);
  font-size: var(--forn-size);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  color: rgb(7, 53, 91);
  &:hover,
  &:focus,
  &:active {
    background-color: rgb(151, 217, 180);
    text-decoration: none;
  }
}